import { useState, useEffect } from 'react'
import React from 'react';
import { Box, Typography, Paper, Divider, CircularProgress, Button } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactMarkdown from 'react-markdown';

function Entry() {
  const { id } = useParams();
  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    // ブログエントリーを読み込む
    const loadBlogEntry = async () => {
      try {
        setLoading(true);
        
        // ファイルパスを構築
        const filePath = `/blog/${id}.md`;
        
        // ファイルの内容を取得
        const response = await fetch(filePath);
        
        // ファイルが存在しない場合はエラー
        if (!response.ok) {
          throw new Error(`ファイル ${id}.md は存在しません`);
        }
        
        const content = await response.text();
        
        // 日付形式に変換（例: '20250226' -> '2025-02-26'）
        const dateStr = `${id.substring(0, 4)}-${id.substring(4, 6)}-${id.substring(6, 8)}`;
        const date = new Date(dateStr);
        
        // 最初の行をタイトルとして扱う（# で始まる場合はマークダウンのタイトル形式）
        let title = content.split('\n')[0];
        if (title.startsWith('# ')) {
          title = title.substring(2);
        }
        
        // 本文（タイトル行を除く）
        const body = content.split('\n').slice(1).join('\n');
        
        setEntry({
          id,
          date,
          title,
          content: body,
          formattedDate: date.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        });
        
        setError(null);
      } catch (error) {
        console.error(`ブログエントリーの読み込みに失敗しました:`, error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    
    if (id) {
      loadBlogEntry();
    }
  }, [id]);
  
  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', my: 4, px: 2 }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Paper sx={{ p: 3, bgcolor: 'error.dark' }}>
          <Typography variant="h6" color="error.contrastText">
            エラー
          </Typography>
          <Typography variant="body1" color="error.contrastText">
            {error}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              component={Link}
              to="/entries"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              sx={{ mt: 1 }}
            >
              記事一覧に戻る
            </Button>
          </Box>
        </Paper>
      ) : entry ? (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            {entry.title}
          </Typography>
          
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            {entry.formattedDate}
          </Typography>
          
          <Divider sx={{ my: 2 }} />
          
          <Box sx={{ my: 3 }}>
            <ReactMarkdown
              components={{
                h1: ({node, ...props}) => <Typography variant="h4" component="h1" gutterBottom {...props} />,
                h2: ({node, ...props}) => <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 3, mb: 2 }} {...props} />,
                h3: ({node, ...props}) => <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 2, mb: 1 }} {...props} />,
                p: ({node, ...props}) => <Typography variant="body1" paragraph {...props} />,
                ul: ({node, ...props}) => <Box component="ul" sx={{ pl: 2, my: 1 }} {...props} />,
                ol: ({node, ...props}) => <Box component="ol" sx={{ pl: 2, my: 1 }} {...props} />,
                li: ({node, ...props}) => <Box component="li" sx={{ my: 0.5 }} {...props} />,
                code: ({node, inline, ...props}) => (
                  inline ? 
                    <Typography component="code" sx={{ bgcolor: 'grey.100', px: 0.5, py: 0.2, borderRadius: 0.5 }} {...props} /> :
                    <Box component="pre" sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1, overflow: 'auto' }}>
                      <Typography component="code" {...props} />
                    </Box>
                ),
                blockquote: ({node, ...props}) => (
                  <Box
                    component="blockquote"
                    sx={{
                      borderLeft: 4,
                      borderColor: 'primary.main',
                      pl: 2,
                      my: 2,
                      fontStyle: 'italic',
                      color: 'text.secondary'
                    }}
                    {...props}
                  />
                ),
                a: ({node, ...props}) => (
                  <Typography
                    component="a"
                    sx={{ color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                    {...props}
                  />
                ),
                img: ({node, ...props}) => (
                  <Box component="img" sx={{ maxWidth: '100%', height: 'auto', my: 2 }} {...props} />
                ),
                hr: ({node, ...props}) => <Divider sx={{ my: 2 }} {...props} />
              }}
            >
              {entry.content}
            </ReactMarkdown>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Box sx={{ mt: 4, mb: 2 }}>
            <Button
              component={Link}
              to="/entries"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              sx={{ mt: 1 }}
            >
              記事一覧に戻る
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
}

export default Entry