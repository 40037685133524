import { useState, useEffect } from 'react'
import React from 'react';
import { Box, Typography, Card, CardContent, CardActionArea, Divider, List, ListItem } from '@mui/material'
import { useParams, Link } from 'react-router-dom'

function Entries() {
  const [blogEntries, setBlogEntries] = useState([]);
  
  useEffect(() => {
    // ブログエントリーを読み込む
    const loadBlogEntries = async () => {
      try {
        // index.txtからmdファイルのリストを取得
        const indexResponse = await fetch('/blog/index.txt');
        
        if (!indexResponse.ok) {
          throw new Error('index.txtの取得に失敗しました');
        }
        
        const indexContent = await indexResponse.text();
        const mdFileList = indexContent.trim().split('\n');
        
        // 各ファイルの内容を取得
        const entries = [];
        
        for (const fileName of mdFileList) {
          // ファイルパスを構築
          const filePath = `/blog/${fileName}`;
          
          try {
            // ファイルの内容を取得
            const response = await fetch(filePath);
            
            // ファイルが存在しない場合はスキップ
            if (!response.ok) {
              console.log(`ファイル ${fileName} は存在しません`);
              continue;
            }
            
            const content = await response.text();
            
            // 日付形式に変換（例: '20250226.md' -> '2025-02-26'）
            const baseName = fileName.replace('.md', '');
            const dateStr = `${baseName.substring(0, 4)}-${baseName.substring(4, 6)}-${baseName.substring(6, 8)}`;
            const date = new Date(dateStr);
            
            // 最初の行をタイトルとして扱う（# で始まる場合はマークダウンのタイトル形式）
            let title = content.split('\n')[0];
            if (title.startsWith('# ')) {
              title = title.substring(2);
            }
            
            entries.push({
              id: baseName,
              date,
              title,
              content,
              formattedDate: date.toLocaleDateString('ja-JP', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })
            });
          } catch (error) {
            console.log(`ファイル ${fileName} の読み込みに失敗しました:`, error);
            // エラーが発生した場合は次のファイルに進む
          }
        }
        
        // 日付の新しい順にソート
        const sortedEntries = entries.sort((a, b) => b.date - a.date);
        
        setBlogEntries(sortedEntries);
      } catch (error) {
        console.error('ブログエントリーの読み込みに失敗しました:', error);
        setBlogEntries([]); // エラー時は空の配列をセット
      }
    };
    
    loadBlogEntries();
  }, []);
  
  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', my: 4, px: 2 }}>
      <List sx={{ width: '100%' }}>
        {blogEntries.map((entry, index) => (
          <React.Fragment key={entry.id}>
            <ListItem disablePadding>
              <Card sx={{ width: '100%', mb: 2 }}>
                <CardActionArea component={Link} to={`/entry/${entry.id}`}>
                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {entry.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      {entry.formattedDate}
                    </Typography>
                    <Typography variant="body1" sx={{
                      whiteSpace: 'pre-wrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical'
                    }}>
                      {entry.content.split('\n').slice(1, 10).join('\n')}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </ListItem>
            {index < blogEntries.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </React.Fragment>
        ))}
      </List>
      
      {blogEntries.length === 0 && (
        <Typography variant="body1" color="text.secondary" align="center" sx={{ my: 4 }}>
          記事がありません
        </Typography>
      )}
    </Box>
  );
}

export default Entries
